import { PersonalInfoReqDTO } from '@/apis/authApis'
import iconComponents from '@/assets/icons/iconComponents'
import Button from '@/common/button/Button'
import FormGroup from '@/common/Input/FormGroup'
import InputWithAdornment from '@/common/Input/InputWithAdornment'
import SelectInput from '@/common/Input/SelectInput'
import Typography from '@/common/Typography'
import { useCountries } from '@/hooks/useCountryStates'
import { useToggleState } from '@/hooks/useToggleState'
import { FormikHelpers, useFormik } from 'formik'
import { personalInfoSchema } from '../validators/signup.validator'
import { errorHandler, withAsync } from '@/helpers/withAsync'
import { useAcceptInvite, useCreateAccount } from '@/store/authStore'
import { useEffect, useMemo, useRef, useState } from 'react'
import Modal from '@/common/modal/Modal'
import clsx from 'clsx'

type Props = {
  onNext: (res: any) => void
  isStartUp?: boolean
  data?: any
}
declare var PasswordCredential: any
const PersonalInformation = ({ onNext, isStartUp, data }: Props) => {
  const PositionRef = useRef<HTMLInputElement>(null)
  const [Countries] = useCountries()
  const [responseData, setResponseData] = useState<any>(null)
  const { state: showPassword, toggle: togglePassword } = useToggleState(false)

  const { mutateAsync: createAccountAction, isLoading: isCreatingAccount } =
    useCreateAccount()
  const { mutateAsync: acceptInviteAction, isLoading: isAcceptingInvite } =
    useAcceptInvite()

  useEffect(() => {
    if (data?.status !== 'old') return
    PositionRef.current?.click()
    PositionRef.current?.focus()
  }, [data?.status])
  const countriesPhones = Countries.map((country: any) =>
    country.phone.split(',')
  )
    .flat()
    .join('|')
  const phoneRegex = new RegExp(`(?:\\\+)?(${countriesPhones})([0-9\\\s]+)`)
  const INITIAL_VALUES: PersonalInfoReqDTO & {
    confirm_password: string
  } = {
    email: data?.email || '',
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    password: '',
    phone_number:
      (countriesPhones &&
        data?.phone_number?.replace?.(phoneRegex, '$1\t$2')) ||
      '234\t',
    confirm_password: '',
    gender: data?.gender || undefined,
  }
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: submitHandler,
    enableReinitialize: true,
    validationSchema: personalInfoSchema(data?.status !== 'old'),
  })
  async function submitHandler(
    values: PersonalInfoReqDTO & {
      confirm_password: string
    },
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<
      PersonalInfoReqDTO & {
        confirm_password: string
      }
    >
  ) {
    const { error, response } = await withAsync(() => {
      const phone_number =
        '+' +
        values.phone_number.split('\t')[0] +
        values.phone_number.split('\t')[1].replace(/^0?/, '')
      const { password, ...rest } = values

      return data
        ? acceptInviteAction({
            ...data,
            ...rest,
            phone_number,
            ...(data?.status !== 'old' ? { password } : {}),
          })
        : createAccountAction({ ...values, phone_number })
    })
    if (error) {
      if (data?.status !== 'old') {
        errorHandler(error)
      } else {
        setApiState('rejected')
      }
      return setSubmitting(false)
    }
    if (response) {
      setSubmitting(false)
      resetForm()

      if (typeof PasswordCredential !== 'undefined' && data?.status !== 'old') {
        const cred = new PasswordCredential({
          id: response.data.data.email,
          password: values.password,
          name: `${values.first_name} ${values.last_name}`,
        })
        navigator.credentials.store(cred).then((creds) => {})
      }
      setResponseData(response.data.data)
      if (data?.status !== 'old') {
        onNext?.(response.data.data)
      } else {
        setApiState('resolved')
        setTimeout(() => {
          onNext?.(response.data.data)
        }, 10000)
      }
    }
  }

  const [apiState, setApiState] = useState<'pending' | 'resolved' | 'rejected'>(
    'pending'
  )
  const CurrentState = useMemo(
    () => ({
      pending: <></>,
      resolved: <ResolvedUi />,
      rejected: <RejectedUi />,
    }),
    []
  )
  return (
    <main className='flex flex-col items-start gap-6 self-stretch'>
      {!isStartUp && (
        <Typography as='h2' heading='sm' className='text-sifuse-shades-900'>
          Personal Information
        </Typography>
      )}
      <form
        onSubmit={handleSubmit}
        className='flex flex-col items-start gap-4 self-stretch md:grid grid-cols-2'
      >
        <FormGroup label='First name' id='first_name' className='col-span-1'>
          <InputWithAdornment
            placeholder='Enter your first name'
            autoComplete='given-name'
            onChange={handleChange}
            onBlur={handleBlur}
            name='first_name'
            value={values.first_name}
            readOnly={data?.status === 'old'}
            error={
              errors.first_name &&
              (touched.first_name as unknown as string) &&
              errors.first_name
            }
          />
        </FormGroup>
        <FormGroup label='Last name' id='last_name' className='col-span-1'>
          <InputWithAdornment
            placeholder='Enter your last name'
            autoComplete='family-name'
            onChange={handleChange}
            onBlur={handleBlur}
            name='last_name'
            value={values.last_name}
            readOnly={data?.status === 'old'}
            error={
              errors.last_name &&
              (touched.last_name as unknown as string) &&
              errors.last_name
            }
          />
        </FormGroup>
        <FormGroup label='Gender' id='gender' className='col-span-2'>
          <SelectInput
            placeholder='Select Gender'
            autoCorrect='gender'
            options={['Male', 'Female']}
            className='w-full'
            onChange={handleChange}
            onBlur={handleBlur}
            name='gender'
            disabled={
              !!data?.gender || (data?.status === 'old' && !!data.gender)
            }
            value={values.gender}
            error={
              errors.gender &&
              (touched.gender as unknown as string) &&
              errors.gender
            }
          />
        </FormGroup>

        {/* <FormGroup
          label='Position (in organization)'
          id='position'
          className='col-span-1'
        >
          <InputWithAdornment
            placeholder='e.g., CEO/CO Founder'
            ref={PositionRef}
            inputMode={'about'}
            autoCorrect='username'
            onChange={handleChange}
            onBlur={handleBlur}
            name='about'
            readOnly={!!data?.about}
            value={values.about}
            error={
              errors.about &&
              (touched.about as unknown as string) &&
              errors.about
            }
          />
        </FormGroup> */}
        <FormGroup
          label='Email address'
          id='email_address'
          className='col-span-2'
        >
          <InputWithAdornment
            type='email'
            placeholder='Enter email address'
            inputMode={'email'}
            autoCorrect='username'
            onChange={handleChange}
            onBlur={handleBlur}
            name='email'
            readOnly={!!data?.email}
            value={values.email}
            error={
              errors.email &&
              (touched.email as unknown as string) &&
              errors.email
            }
          />
        </FormGroup>
        <FormGroup
          label='Phone number'
          id='phone_number'
          className='col-span-2'
        >
          <div className='flex items-center w-full gap-3'>
            <SelectInput
              options={Countries}
              optionsDisplay={(country) =>
                country?.emoji ? (
                  <span className='flex items-center gap-2.5'>
                    <span className='h-4 max-w-[22px]'>{country.flag}</span>
                    <span className='text-sifuse-shades-600 text-sm leading-5 '>
                      +{country?.phone}
                    </span>
                  </span>
                ) : (
                  'Country'
                )
              }
              menuClassName='!mr-0 overflow-x-hidden whitespace-normal'
              optionsText={(country) =>
                country?.emoji ? (
                  <span className='flex items-center gap-2.5'>
                    <span className='h-4 w-[22px]'>{country?.flag}</span>
                    <span>{country?.name}</span>
                    <span className='text-sifuse-shades-600 text-sm leading-5'>
                      (+{country?.phone})
                    </span>
                  </span>
                ) : (
                  'Country'
                )
              }
              optionsValue={(country) => {
                return country?.phone
              }}
              onChange={(e) => {
                const number = values.phone_number.split('\t')[1]
                setFieldValue(
                  'phone_number',
                  e.currentTarget.value + '\t' + number
                )
              }}
              placeholder='Select Country'
              value={values.phone_number.split('\t')[0].trim()}
              error={
                errors.phone_number &&
                (touched.phone_number as unknown as string) &&
                'Phone number incorrect'
              }
            />
            <InputWithAdornment
              placeholder='Enter phone number'
              inputMode={'tel'}
              autoComplete='tel-local'
              onChange={(e) => {
                const code = values.phone_number.split('\t')[0].trim()
                setFieldValue(
                  'phone_number',
                  code + '\t' + e.currentTarget.value
                )
              }}
              onBlur={handleBlur}
              name='phone_number'
              value={values.phone_number?.split('\t')[1]}
              error={
                errors.phone_number &&
                (touched.phone_number as unknown as string) &&
                errors.phone_number
              }
            />
          </div>
        </FormGroup>
        {data?.status !== 'old' && (
          <>
            <FormGroup label='Passsword' id='password' className='col-span-2'>
              <InputWithAdornment
                type={showPassword ? 'text' : 'password'}
                placeholder='Enter your passsword'
                autoComplete='new-password'
                right={
                  <button type='button' onClick={togglePassword}>
                    {showPassword ? (
                      <iconComponents.auth.EyeCloseIcon />
                    ) : (
                      <iconComponents.auth.EyeOpenIcon />
                    )}
                  </button>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                name='password'
                value={values.password}
                error={
                  errors.password &&
                  (touched.password as unknown as string) &&
                  errors.password
                }
              />
            </FormGroup>
            <FormGroup
              label='Confirm passsword'
              id='password_confirm'
              className='col-span-2'
            >
              <InputWithAdornment
                type={showPassword ? 'text' : 'password'}
                placeholder='Enter your passsword again'
                autoComplete='new-password'
                right={
                  <button type='button' onClick={togglePassword}>
                    {showPassword ? (
                      <iconComponents.auth.EyeCloseIcon />
                    ) : (
                      <iconComponents.auth.EyeOpenIcon />
                    )}
                  </button>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                name='confirm_password'
                value={values.confirm_password}
                error={
                  errors.confirm_password &&
                  (touched.confirm_password as unknown as string) &&
                  errors.confirm_password
                }
              />
            </FormGroup>
          </>
        )}
        <Button
          type='submit'
          color='primary'
          size='xl'
          loading={isAcceptingInvite || isCreatingAccount || isSubmitting}
          disabled={
            !isValid || isSubmitting || isCreatingAccount || isAcceptingInvite
          }
          className='w-full text-center  mt-8 col-span-2'
        >
          {isStartUp ? `Join ${data.category}` : 'Next'}
        </Button>
      </form>
      <Modal
        variant='md'
        enableWarning={false}
        onClose={() => {}}
        open={['rejected', 'resolved'].includes(apiState)}
      >
        <main
          className={clsx(
            'm-6 flex flex-col  py-12 pb-2 items-center justify-center gap-4 self-stretch',
            ['pending'].includes(apiState) ? '' : ' gap-5'
          )}
        >
          {CurrentState[apiState]}
        </main>
        {apiState === 'rejected' && (
          <footer className='mb-10 w-full flex items-center justify-center'>
            <Button
              color='white'
              size='lg'
              className='bg-sifuse-shades-100'
              endIcon={<iconComponents.util.RightArrowIcon />}
              onClick={() => onNext(responseData)}
            >
              Proceed
            </Button>
          </footer>
        )}
      </Modal>
    </main>
  )
}

const ResolvedUi = () => {
  return (
    <>
      <div className='w-full flex items-center justify-center'>
        <iconComponents.util.VerifyIcon />
      </div>
      <Typography as='h4' heading='xs' className='text-sifuse-shades-800'>
        Joined Successfully
      </Typography>
      <Typography
        as='p'
        paragraph='lg'
        className='text-sifuse-shades-600 text-center max-w-[383px]'
      >
        You have successfully joined the the team
      </Typography>
    </>
  )
}
const RejectedUi = () => {
  return (
    <>
      <div className='w-full flex items-center justify-center'>
        <iconComponents.util.DangerIcon />
      </div>
      <Typography as='h4' heading='xs' className='text-sifuse-shades-800'>
        Link Expired
      </Typography>
      <Typography
        as='p'
        paragraph='lg'
        className='text-sifuse-shades-600 text-center max-w-[383px]'
      >
        Invitation link has expired, kindly request for another invitation to
        join the team
      </Typography>
    </>
  )
}
export default PersonalInformation
